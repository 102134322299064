import { createApp } from 'vue'
import App from './App.vue'
import store from '@/store'
import router from "../src/router";
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import InfiniteScroll from 'vue-infinite-scroll'
import Lazyload from './utils/lazy.js'
import vueWatermark from 'vue-watermark-directive'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const DEFAULT_CONFIG = {
  zIndex: 1000,
  font: '8px microsoft yahei',
  color: 'rgba(0,0,0,0.3)',
  content: '烨潮设计',
  rotate: 20,
  width: 40,
  height: 40,
  repeat: true
}
app.use(vueWatermark, DEFAULT_CONFIG);
app.use(router);
app.use(store);
app.use(InfiniteScroll)
// 使用懒加载插件
app.use(Lazyload)
app.use(ElementPlus, {
  locale: zhCn
})
app.mount("#app");